export const USER_PROFILE_IMAGE_UPLOAD_REQUEST =
  'USER_PROFILE_IMAGE_UPLOAD_REQUEST';
export const USER_PROFILE_IMAGE_UPLOAD_SUCCESS =
  'USER_PROFILE_IMAGE_UPLOAD_SUCCESS';
export const USER_PROFILE_IMAGE_UPLOAD_FAILURE =
  'USER_PROFILE_IMAGE_UPLOAD_FAILURE';
export const USER_PROFILE_IMAGE_UPLOAD_RESET =
  'USER_PROFILE_IMAGE_UPLOAD_RESET';

//Memories DELETE images
export const USER_PROFILE_IMAGE_DELETE_REQUEST =
  'USER_PROFILE_IMAGE_DELETE_REQUEST';
export const USER_PROFILE_IMAGE_DELETE_SUCCESS =
  'USER_PROFILE_IMAGE_DELETE_SUCCESS';
export const USER_PROFILE_IMAGE_DELETE_FAILURE =
  'USER_PROFILE_IMAGE_DELETE_FAILURE';
export const USER_PROFILE_IMAGE_DELETE_RESET =
  'USER_PROFILE_IMAGE_DELETE_RESET';

//Memories images
export const MEMORY_IMAGE_UPLOAD_REQUEST = 'MEMORY_IMAGE_UPLOAD_REQUEST';
export const MEMORY_IMAGE_UPLOAD_SUCCESS = 'MEMORY_IMAGE_UPLOAD_SUCCESS';
export const MEMORY_IMAGE_UPLOAD_FAILURE = 'MEMORY_IMAGE_UPLOAD_FAILURE';
export const MEMORY_IMAGE_UPLOAD_RESET = 'MEMORY_IMAGE_UPLOAD_RESET';

//Memories DELETE images
export const MEMORY_IMAGE_DELETE_REQUEST = 'MEMORY_IMAGE_DELETE_REQUEST';
export const MEMORY_IMAGE_DELETE_SUCCESS = 'MEMORY_IMAGE_DELETE_SUCCESS';
export const MEMORY_IMAGE_DELETE_FAILURE = 'MEMORY_IMAGE_DELETE_FAILURE';
export const MEMORY_IMAGE_DELETE_RESET = 'MEMORY_IMAGE_DELETE_RESET';
