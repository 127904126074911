export const MEMORIES_GET_REQUEST = 'MEMORIES_GET_REQUEST';
export const MEMORIES_GET_SUCCESS = 'MEMORIES_GET_SUCCESS';
export const MEMORIES_GET_FAILURE = 'MEMORIES_GET_FAILURE';
export const MEMORIES_GET_RESET = 'MEMORIES_GET_RESET';

export const MEMORIES_CREATE_REQUEST = 'MEMORIES_CREATE_REQUEST';
export const MEMORIES_CREATE_SUCCESS = 'MEMORIES_CREATE_SUCCESS';
export const MEMORIES_CREATE_FAILURE = 'MEMORIES_CREATE_FAILURE';
export const MEMORIES_CREATE_RESET = 'MEMORIES_CREATE_RESET';

export const MEMORIES_EDIT_REQUEST = 'MEMORIES_EDIT_REQUEST';
export const MEMORIES_EDIT_SUCCESS = 'MEMORIES_EDIT_SUCCESS';
export const MEMORIES_EDIT_FAILURE = 'MEMORIES_EDIT_FAILURE';
export const MEMORIES_EDIT_RESET = 'MEMORIES_EDIT_RESET';

export const MEMORIES_DELETE_REQUEST = 'MEMORIES_DELETE_REQUEST';
export const MEMORIES_DELETE_SUCCESS = 'MEMORIES_DELETE_SUCCESS';
export const MEMORIES_DELETE_FAILURE = 'MEMORIES_DELETE_FAILURE';
export const MEMORIES_DELETE_RESET = 'MEMORIES_DELETE_RESET';

export const MEMORIES_DELETE_TAG_REQUEST = 'MEMORIES_DELETE_TAG_REQUEST';
export const MEMORIES_DELETE_TAG_SUCCESS = 'MEMORIES_DELETE_TAG_SUCCESS';
export const MEMORIES_DELETE_TAG_FAILURE = 'MEMORIES_DELETE_TAG_FAILURE';
export const MEMORIES_DELETE_TAG_RESET = 'MEMORIES_DELETE_TAG_RESET';

export const MEMORIES_SET_DUE_DATE_REQUEST = 'MEMORIES_SET_DUE_DATE_REQUEST';
export const MEMORIES_SET_DUE_DATE_SUCCESS = 'MEMORIES_SET_DUE_DATE_SUCCESS';
export const MEMORIES_SET_DUE_DATE_FAILURE = 'MEMORIES_SET_DUE_DATE_FAILURE';
export const MEMORIES_SET_DUE_DATE_RESET = 'MEMORIES_SET_DUE_DATE_RESET';

export const MEMORIES_IS_COMPETE_REQUEST = 'MEMORIES_IS_COMPETE_REQUEST';
export const MEMORIES_IS_COMPETE_SUCCESS = 'MEMORIES_IS_COMPETE_SUCCESS';
export const MEMORIES_IS_COMPETE_FAILURE = 'MEMORIES_IS_COMPETE_FAILURE';
export const MEMORIES_IS_COMPETE_RESET = 'MEMORIES_IS_COMPETE_RESET';
